@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

html {
  height: 100vh;
}

.wave {
  clip-path: polygon(
    100% 100%,
    0% 100%,
    0% 11.52%,
    1.11% 10.96%,
    2.22% 10.4%,
    3.33% 9.83%,
    4.44% 9.26%,
    5.56% 8.7%,
    6.67% 8.14%,
    7.78% 7.59%,
    8.89% 7.04%,
    10% 6.52%,
    11.11% 6.01%,
    12.22% 5.53%,
    13.33% 5.06%,
    14.44% 4.63%,
    15.56% 4.22%,
    16.67% 3.85%,
    17.78% 3.51%,
    18.89% 3.2%,
    20% 2.94%,
    21.11% 2.71%,
    22.22% 2.52%,
    23.33% 2.38%,
    24.44% 2.28%,
    25.56% 2.22%,
    26.67% 2.2%,
    27.78% 2.23%,
    28.89% 2.3%,
    30% 2.41%,
    31.11% 2.57%,
    32.22% 2.77%,
    33.33% 3.01%,
    34.44% 3.28%,
    35.56% 3.6%,
    36.67% 3.95%,
    37.78% 4.33%,
    38.89% 4.75%,
    40% 5.19%,
    41.11% 5.66%,
    42.22% 6.15%,
    43.33% 6.66%,
    44.44% 7.19%,
    45.56% 7.73%,
    46.67% 8.29%,
    47.78% 8.85%,
    48.89% 9.42%,
    50% 9.99%,
    51.11% 10.55%,
    52.22% 11.11%,
    53.33% 11.66%,
    54.44% 12.2%,
    55.56% 12.73%,
    56.67% 13.23%,
    57.78% 13.72%,
    58.89% 14.18%,
    60% 14.61%,
    61.11% 15.01%,
    62.22% 15.38%,
    63.33% 15.72%,
    64.44% 16.02%,
    65.56% 16.28%,
    66.67% 16.51%,
    67.78% 16.69%,
    68.89% 16.83%,
    70% 16.93%,
    71.11% 16.99%,
    72.22% 17%,
    73.33% 16.97%,
    74.44% 16.89%,
    75.56% 16.77%,
    76.67% 16.61%,
    77.78% 16.41%,
    78.89% 16.17%,
    80% 15.89%,
    81.11% 15.57%,
    82.22% 15.22%,
    83.33% 14.83%,
    84.44% 14.42%,
    85.56% 13.97%,
    86.67% 13.5%,
    87.78% 13.01%,
    88.89% 12.49%,
    90% 11.96%,
    91.11% 11.42%,
    92.22% 10.86%,
    93.33% 10.3%,
    94.44% 9.73%,
    95.56% 9.16%,
    96.67% 8.6%,
    97.78% 8.04%,
    98.89% 7.49%,
    100% 6.95%
  );
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  height: 700px;
  border-radius: 50%;
  background-color: #df6789;
  opacity: 16%;
  /* background: linear-gradient(to right, #E8E3ED -10%, #FFFFFF, #FFFFFF); */
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); */
  z-index: 1;
  filter: blur(60px);
}

@media (max-width: 512px) {
  .hero-section::before {
    max-width: 300px;
    height: 270px;
    top: 43%;
    filter: blur(40px);
  }
}

.purple-section-shadow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  max-width: 400px;
  width: 100%;
  height: 920px;
  background-color: #3f1562;
  opacity: 16%;
  /* background: linear-gradient(to right, #E8E3ED -10%, #FFFFFF, #FFFFFF); */
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); */
  filter: blur(190px);
}

.pink-section-shadow::after {
  content: "";
  position: absolute;
  top: -130px;
  right: 0;
  max-width: 220px;
  width: 100%;
  height: 490px;
  background-color: #df6789;
  opacity: 16%;
  z-index: -1;
  filter: blur(80px);
}

.linear-gradient-bg {
  background: linear-gradient(to right, #eee2e9, #f4eaf0, #ece8ef);
}

body.blur {
  filter: blur(4px);
  transition: filter 0.3s ease;
}

footer {
  background: linear-gradient(to right, #3f1562, #562082, #3f1562);
  box-shadow: inset 100px 100px 100px 100px #3f1562;
}

.talent-section {
  background: linear-gradient(to right, #3f1562, #562082, #3f1562);
  box-shadow: inset 50px 50px 50px 50px #3f1562;
}

@media (max-width: 1440px) {
  .bridging-durbin-img {
    padding-top: 80px;
  }
}
@media (max-width: 1280px) {
  .bridging-durbin-img {
    padding-top: 160px;
  }
}


body {
  color: var(--foreground);
  background: var(--background);
  font-family: "Manrope", sans-serif;
}
